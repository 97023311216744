// devVersioning.js

export const isDevMode = false; // Set to false in production

export function fetchVersionNumber() {
    fetch('tools/lastUpdate.txt')
        .then(response => response.text())
        .then(version => {
            const versionElement = document.getElementById('lowerLeft-content');
            if (versionElement) {
                versionElement.innerHTML = version;
            } else {
                console.error('Version element not found');
            }
        })
        .catch(error => console.error('Error fetching version:', error));
}