//upperClockDisplay.js

// export let tooltip;

export const timeXCoords = {
    startX: 0,
    wakeX: null,
    sleepX: null,
    endX: null,
    nowX: null
  };

import { waveAmplitude, centerY, updateWakeSleepX } from "./calculation";

import { neonColor } from "./glowStyleDisplay";

import {
    baseCtx,
    glowCtx,
    glowCanvas,
    baseCanvas
} from "./setup";

import {
    clockStyle,
    wakeLineStyle,
    wakeLineGlowStyle,
    sleepLineStyle,
    sleepLineGlowStyle,
    wakeDotStyle,
    wakeDotGlowStyle,
    sleepDotStyle,
    sleepDotGlowStyle
} from "./glowStyleDisplay";

let waveFrequency = 2;


export function getYPositionOfWave(x) {
    return centerY - waveAmplitude * Math.sin((x / baseCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
}

//////////////////////////////////////////////////////////////////////////////
// Draw 'static' elements on baseCanvas
export function drawClockGrid() {
    // console.log('starting drawClockGrid wake, sleep', wakeTime, sleepTime);
    // console.log('drawClockGrid function');
    // console.log('x values - start, wake, sleep, end, now: ', timeXCoords.startX, timeXCoords.wakeX, timeXCoords.sleepX, timeXCoords.endX, timeXCoords.nowX);
    drawTopLine();
    drawHourLines();
    drawSleepTimeFill();
    drawWakeSleepLine();
    drawWakeLineHighlight();
    drawSleepLineHighlight();
    negativeSpaceFill();
    // console.log('ending drawClockGrid wake, sleep', wakeTime, sleepTime);
}

function drawTopLine() {
    baseCtx.beginPath();
    baseCtx.moveTo(timeXCoords.startX, 0);
    baseCtx.lineTo(timeXCoords.endX, 0);
    baseCtx.strokeStyle = '#1e4f55';
    baseCtx.lineWidth = 2;
    baseCtx.stroke();
}


function drawHourLines() {
    // console.log('drawingHourLines wake, sleep', wakeTime, sleepTime);
    const offsetResults = updateWakeSleepX();
    const midpointPosition = offsetResults.midpointPosition;

    // Draw all hour lines
    baseCtx.beginPath();
    for (let hour = 0; hour < 24; hour++) {
        let hourInMinutes = hour * 60;
        let rawX = hourInMinutes * (baseCanvas.width / 1440);
        let x = (rawX - (midpointPosition - (baseCanvas.width / 2)) + baseCanvas.width) % baseCanvas.width;

        baseCtx.moveTo(x, 0);
        baseCtx.lineTo(x, baseCanvas.height);
    }
    baseCtx.strokeStyle = '#1e4f55';
    baseCtx.lineWidth = 1.2;
    baseCtx.stroke();
}

function drawSleepTimeFill() {
        // console.log('draw StaticOverlays startX, ');
    const styles = clockStyle();
    const sleepFill = styles.sleepFill;
    const sleepFillAlpha = styles.sleepFillAlpha;

    //fill in before wake.
    baseCtx.beginPath(); // Start a new path for the overlay
        // console.log('startX: ', timeXCoords.startX);
    baseCtx.moveTo(timeXCoords.startX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.startX, getYPositionOfWave(0));
    for (let x = timeXCoords.startX; x <= timeXCoords.wakeX; x++) {
        baseCtx.lineTo(x, getYPositionOfWave(x));
    }
    baseCtx.lineTo(timeXCoords.wakeX, baseCtx.canvas.height);
    baseCtx.closePath();
    baseCtx.fillStyle = sleepFill;
    baseCtx.globalAlpha = sleepFillAlpha;
    baseCtx.fill();

    //Fill in after Sleep.
    baseCtx.beginPath();
    baseCtx.moveTo(timeXCoords.sleepX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.sleepX, getYPositionOfWave(timeXCoords.sleepX));
    for (let x = timeXCoords.sleepX; x <= timeXCoords.endX; x++) {
        baseCtx.lineTo(x, getYPositionOfWave(x));
    }
    baseCtx.lineTo(timeXCoords.endX, baseCtx.canvas.height);
    baseCtx.closePath();
    baseCtx.fill();
    baseCtx.globalAlpha = 1;
}

function drawWakeLineHighlight() {
    const styles = clockStyle();
    const wakeSleepAlpha = styles.wakeLineHighlightAlpha;
    baseCtx.beginPath();
    baseCtx.moveTo(timeXCoords.wakeX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.wakeX, getYPositionOfWave(timeXCoords.wakeX));
    baseCtx.strokeStyle = neonColor;
    baseCtx.lineWidth = 2;
    baseCtx.globalAlpha = wakeSleepAlpha;
    baseCtx.stroke();
    baseCtx.globalAlpha = 1;
}

function drawSleepLineHighlight() {
    const styles = clockStyle();
    const wakeSleepAlpha = styles.sleepLineHighlightAlpha;
    baseCtx.beginPath();
    baseCtx.moveTo(timeXCoords.sleepX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.sleepX, getYPositionOfWave(timeXCoords.sleepX));
    baseCtx.strokeStyle = neonColor;
    baseCtx.lineWidth = 2;
    baseCtx.globalAlpha = wakeSleepAlpha;
    baseCtx.stroke();
    baseCtx.globalAlpha = 1;
}


function drawWakeSleepLine() {
    let wakeY = getYPositionOfWave(timeXCoords.wakeX);
    let sleepY = getYPositionOfWave(timeXCoords.sleepX);
    let m = (sleepY - wakeY) / (timeXCoords.sleepX - timeXCoords.wakeX);
    // Draw the wake-sleep line
    baseCtx.beginPath();
    let yInterceptAtZero = wakeY - timeXCoords.wakeX * m; // y-intercept at x=0
    let yInterceptAtCanvasWidth = wakeY + (baseCanvas.width - timeXCoords.wakeX) * m; // y-intercept at x=canvas.width
    baseCtx.moveTo(0, yInterceptAtZero);
    baseCtx.lineTo(baseCanvas.width, yInterceptAtCanvasWidth);
    baseCtx.strokeStyle = '#1e4f55';
    baseCtx.lineWidth = 1;
    baseCtx.stroke();

/////////////////////Draw Colored Wake Time Sleep Time Dots//////////////////////
    // baseCtx.beginPath();
    // baseCtx.arc(timeXCoords.wakeX, wakeY, 5, 0, 2 * Math.PI);
    // baseCtx.fillStyle = 'red';
    // baseCtx.fill();
    //     console.log ('baseCanvas.width: ', baseCanvas.width);
    //     console.log('Wake dot is drawing at: ', timeXCoords.wakeX, wakeY);

    // baseCtx.beginPath();
    // baseCtx.arc(sleepX, sleepY, 5, 0, 2 * Math.PI);
    // baseCtx.fillStyle = 'blue';
    // baseCtx.fill();
    //     console.log('Sleep dot is drawing at: ', sleepX, sleepY);
}

function negativeSpaceFill() {
    const styles = clockStyle();
    const topOverlayAlpha = styles.topOverlayAlpha
    const topOverlayColor = styles.topOverlayColor
    //Fill in after Sleep.
    baseCtx.beginPath(); // Start a new path for the overlay
    baseCtx.moveTo(timeXCoords.startX, 0);
    baseCtx.lineTo(timeXCoords.startX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.wakeX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.wakeX, getYPositionOfWave(timeXCoords.wakeX));
    for (let x = timeXCoords.startX; x <= timeXCoords.sleepX; x++) {
        baseCtx.lineTo(x, getYPositionOfWave(x));
    }
    baseCtx.lineTo(timeXCoords.sleepX, baseCtx.canvas.height);
    baseCtx.lineTo(timeXCoords.endX, baseCtx.canvas.height);  
    baseCtx.lineTo(timeXCoords.endX, 0);
    baseCtx.closePath();
    baseCtx.fillStyle = topOverlayColor; // Adjust the color and opacity as needed
    baseCtx.globalAlpha = topOverlayAlpha;
    baseCtx.fill();
    baseCtx.globalAlpha = 1;
}

///////////////////////////////////////////////////////////////////////////////////
//draw dynamic clock elements on glowCanvas

export function drawTimeGlow(timeXCoords) {
    glowCtx.clearRect(0, 0, glowCanvas.width, glowCanvas.height);
    // Drawing the Glowing Dot (style determined by if nowX is within wake period)
    const isWakePeriod = timeXCoords.nowX >= timeXCoords.wakeX && timeXCoords.nowX <= timeXCoords.sleepX;
    const dotStyleToUse = isWakePeriod ? wakeDotStyle() : sleepDotStyle(); // Call the function to get the style object
        // console.log('drawTimeGlow Function is kicking off!!');
        // console.log('startX: ', timeXCoords.startX);
        // console.log('wakeX: ', timeXCoords.wakeX);
        // console.log('sleepX: ', timeXCoords.sleepX);
        // console.log('endX: ', timeXCoords.endX);
        // console.log('nowX: ', timeXCoords.nowX);
    // Access the style settings
    const styles = clockStyle();
    const nowHourLineAlpha = styles.nowHourLineAlpha;
    // Clear the canvas before redrawing
    //glowCtx.clearRect(0, 0, glowCanvas.width, glowCanvas.height);
        // console.log('wake,sleep,now: ',timeXCoords.wakeX, timeXCoords.sleepX, timeXCoords.nowX);
    if (timeXCoords.nowX < timeXCoords.wakeX) {
        // console.log (' its before wake time')
        // console.log('Drawing am sleep line to now - from ', timeXCoords.startX, 'to', timeXCoords.nowX);
        // Draw sleep style from startX to nowX (before wake up time)
        drawSleepLineWithGlow(0, timeXCoords.nowX, sleepLineStyle());
        drawBaseTimeLine(timeXCoords.nowX,timeXCoords.endX);
        drawSleepGlowDot(timeXCoords.nowX, centerY, dotStyleToUse);
    } else if (timeXCoords.nowX >= timeXCoords.wakeX && timeXCoords.nowX <= timeXCoords.sleepX) {
    // console.log('its between wake time and sleep time');
        drawTimeFill();
        // console.log('Drawing sleep line to wake - from ', timeXCoords.startX, 'to', timeXCoords.wakeX);
        // Draw sleep style from startX to wakeX (after wake up time but before go to sleep time)
        drawSleepLineWithGlow(0, timeXCoords.wakeX, sleepLineStyle());
        // Then draw wake style from wakeX to nowX
        // console.log('Drawing wake line to now - from ', timeXCoords.wakeX, 'to', timeXCoords.nowX);
        drawWakeLineWithGlow(timeXCoords.wakeX, timeXCoords.nowX, wakeLineStyle());
        //drawTimeFill(timeXCoords.wakeX, timeXCoords.nowX);
        drawBaseTimeLine(timeXCoords.nowX,timeXCoords.endX);
        drawWakeGlowDot(timeXCoords.nowX, centerY, dotStyleToUse);
    } else {
        // console.log ('its after sleep time');
        drawTimeFilled();
        drawSleepLineWithGlow(0, timeXCoords.nowX, sleepLineStyle());
        drawBaseTimeLine(timeXCoords.nowX,timeXCoords.endX);
        drawSleepGlowDot(timeXCoords.nowX, centerY, dotStyleToUse);
    }

    drawBaseLineHighlight();

    // Draw the vertical line for the current time
    glowCtx.globalAlpha = nowHourLineAlpha;
    drawNowLine(timeXCoords);
    drawHighlightedHourLine();
    glowCtx.globalAlpha = 1;
}

// Use this function to draw the wake line with glow
function drawWakeLineWithGlow(startX, endX) {
    const lineStyle = wakeLineStyle();
    const glowStyle = wakeLineGlowStyle();
    const glowOffsetY = glowCanvas.height;

    // Draw glow line first
    glowCtx.beginPath();
    for (let x = startX; x <= endX; x++) {
        let y = centerY + glowOffsetY - waveAmplitude * Math.sin((x / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
        if (x === startX) {
            glowCtx.moveTo(x, y); // Start from this point
        } else {
            glowCtx.lineTo(x, y); // Draw the line segment
        }
    }
    glowCtx.globalAlpha = glowStyle.glowAlpha;
    glowCtx.shadowBlur = glowStyle.glowBlur;
    glowCtx.shadowColor = glowStyle.glowColor;
    glowCtx.strokeStyle = glowStyle.glowColor;
    glowCtx.lineWidth = glowStyle.glowWidth;
    glowCtx.shadowOffsetY = -glowOffsetY;
    glowCtx.stroke();

    // Reset shadow properties and draw the main line
    glowCtx.globalAlpha = 1;
    glowCtx.shadowBlur = 0;
    glowCtx.shadowColor = 'transparent';
    glowCtx.beginPath();
    for (let x = startX; x <= endX; x++) {
        const angle = (x / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2;
        let y = centerY - waveAmplitude * Math.sin(angle);
        if (x === startX) {
            glowCtx.moveTo(x, y); // Start from this point
        } else {
            glowCtx.lineTo(x, y); // Draw the line segment
        }
    }
    glowCtx.lineWidth = lineStyle.lineWidth;
    glowCtx.strokeStyle = lineStyle.strokeStyle;
    glowCtx.globalAlpha = glowStyle.lineAlpha;
    glowCtx.stroke();
}

// Use this function to draw the sleep line with glow
function drawSleepLineWithGlow(startX, endX) {
    const lineStyle = sleepLineStyle();
    const glowStyle = sleepLineGlowStyle();
    const glowOffsetY = glowCanvas.height;

    // Draw glow line first
    glowCtx.beginPath();
    for (let x = startX; x <= endX; x++) {
        let y = centerY + glowOffsetY - waveAmplitude * Math.sin((x / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
        if (x === startX) {
            glowCtx.moveTo(x, y); // Start from this point
        } else {
            glowCtx.lineTo(x, y); // Draw the line segment
        }
    }
    glowCtx.globalAlpha = glowStyle.glowAlpha;
    glowCtx.shadowBlur = glowStyle.glowBlur;
    glowCtx.shadowColor = glowStyle.glowColor;
    glowCtx.strokeStyle = glowStyle.glowColor;
    glowCtx.lineWidth = glowStyle.glowWidth;
    glowCtx.shadowOffsetY = -glowOffsetY;
    glowCtx.stroke();

    // Reset shadow properties and draw the main line
    glowCtx.globalAlpha = 1;
    glowCtx.shadowBlur = 0;
    glowCtx.shadowColor = 'transparent';
    glowCtx.beginPath();
    for (let x = startX; x <= endX; x++) {
        const angle = (x / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2;
        let y = centerY - waveAmplitude * Math.sin(angle);
        if (x === startX) {
            glowCtx.moveTo(x, y); // Start from this point
        } else {
            glowCtx.lineTo(x, y); // Draw the line segment
        }
    }
    glowCtx.globalAlpha = lineStyle.glowAlpha;
    glowCtx.lineWidth = lineStyle.lineWidth;
    glowCtx.strokeStyle = lineStyle.strokeStyle;
    glowCtx.globalAlpha = glowStyle.lineAlpha;
    glowCtx.stroke();
}

// Use this function to draw the remainder of the base sine curve
function drawBaseTimeLine(startX, endX) {
    const styles = clockStyle();
    const baseLineColor = styles.baseLineColor;
    const baseLineAlpha = styles.baseLineAlpha;
    glowCtx.beginPath();
    for (let x = startX; x <= endX; x++) {
        let y = centerY - waveAmplitude * Math.sin((x / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
        if (x === startX) {
            glowCtx.moveTo(x, y); // Move to the start point without drawing a line
        } else {
            glowCtx.lineTo(x, y); // Draw the line segment
        }
    }
    glowCtx.strokeStyle = baseLineColor;
    glowCtx.globalAlpha = baseLineAlpha;
    glowCtx.stroke();
    glowCtx.globalAlpha = 1;
}

function drawWakeGlowDot() {
    // Glow effect for dot
    const waveYPosition = centerY - waveAmplitude * Math.sin((timeXCoords.nowX / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
    const dotGlowStyle = wakeDotGlowStyle();
    const dotStyle = wakeDotStyle();

    glowCtx.beginPath();
    glowCtx.arc(timeXCoords.nowX, waveYPosition + glowCanvas.height, dotGlowStyle.glowRadius, 0, 2 * Math.PI);
    glowCtx.globalAlpha = dotGlowStyle.glowAlpha;
    glowCtx.fillStyle = dotGlowStyle.glowColor;
    glowCtx.shadowBlur = dotGlowStyle.glowRadius;
    glowCtx.shadowColor = dotGlowStyle.glowColor;
    glowCtx.shadowOffsetY = dotGlowStyle.glowOffsetY;
    glowCtx.fill();
    // Reset glow effect to draw main dot
    glowCtx.globalAlpha = 1;
    glowCtx.shadowBlur = 0;
    glowCtx.shadowColor = 'transparent';
    glowCtx.shadowOffsetY = 0;

    // Main dot
    glowCtx.beginPath();
    glowCtx.arc(timeXCoords.nowX, waveYPosition, dotStyle.radius, 0, 2 * Math.PI);
    glowCtx.globalAlpha = dotStyle.glowAlpha; 
    glowCtx.fillStyle = dotStyle.fillColor;
    glowCtx.fill();
} 

function drawSleepGlowDot() {
    // Glow effect for dot
    const waveYPosition = centerY - waveAmplitude * Math.sin((timeXCoords.nowX / glowCanvas.width) * waveFrequency * Math.PI - Math.PI / 2);
    const dotGlowStyle = sleepDotGlowStyle();
    const dotStyle = sleepDotStyle();

    glowCtx.beginPath();
    glowCtx.arc(timeXCoords.nowX, waveYPosition + glowCanvas.height, dotGlowStyle.glowRadius, 0, 2 * Math.PI);
    glowCtx.globalAlpha = dotGlowStyle.glowAlpha;
    glowCtx.fillStyle = dotGlowStyle.glowColor;
    glowCtx.shadowBlur = dotGlowStyle.glowRadius;
    glowCtx.shadowColor = dotGlowStyle.glowColor;
    glowCtx.shadowOffsetY = dotGlowStyle.glowOffsetY;
    glowCtx.fill();
    // Reset glow effect to draw main dot
    glowCtx.globalAlpha = 1;
    glowCtx.shadowBlur = 0;
    glowCtx.shadowColor = 'transparent';
    glowCtx.shadowOffsetY = 0;

    // Main dot
    glowCtx.beginPath();
    glowCtx.arc(timeXCoords.nowX, waveYPosition, dotStyle.radius, 0, 2 * Math.PI);
    glowCtx.globalAlpha = dotStyle.glowAlpha;
    glowCtx.fillStyle = dotStyle.fillColor;
    glowCtx.fill();
} 

function drawTimeFill() {
    const styles = clockStyle();
    const timeFill = styles.timeFill;
    const timeFillAlpha = styles.timeFillAlpha;
    //Fill in after Sleep.
    glowCtx.beginPath(); // Start a new path for the overlay
    glowCtx.moveTo(timeXCoords.wakeX, baseCtx.canvas.height);
    glowCtx.lineTo(timeXCoords.wakeX, getYPositionOfWave(timeXCoords.wakeX));
    for (let x = timeXCoords.wakeX; x <= timeXCoords.nowX; x++) {
        glowCtx.lineTo(x, getYPositionOfWave(x));
    }
    glowCtx.lineTo(timeXCoords.nowX, baseCtx.canvas.height);
    glowCtx.fillStyle = timeFill; // Adjust the color and opacity as needed
    glowCtx.globalAlpha = timeFillAlpha;
    glowCtx.closePath();
    glowCtx.fill();
    glowCtx.globalAlpha = 1;
}

function drawTimeFilled() {
    const styles = clockStyle();
    const sleepFill = styles.sleepFill;
    const sleepFillAlpha = styles.sleepFillAlpha;
    //Fill in after Sleep.
    glowCtx.beginPath(); // Start a new path for the overlay
    glowCtx.moveTo(timeXCoords.wakeX, baseCtx.canvas.height);
    glowCtx.lineTo(timeXCoords.wakeX, getYPositionOfWave(timeXCoords.wakeX));
    for (let x = timeXCoords.wakeX; x <= timeXCoords.sleepX; x++) {
        glowCtx.lineTo(x, getYPositionOfWave(x));
    }
    glowCtx.lineTo(timeXCoords.sleepX, baseCtx.canvas.height);
    glowCtx.closePath();
    glowCtx.fillStyle = sleepFill;
    glowCtx.globalAlpha = sleepFillAlpha;
    glowCtx.fill();
    glowCtx.globalAlpha = 1;
}


function drawBaseLineHighlight() {
    const styles = clockStyle();
    const baseLineAlpha = styles.baseLineHighlightAlpha;
    // Check if nowX is within the range of wakeX and sleepX
    if (timeXCoords.nowX >= timeXCoords.wakeX && timeXCoords.nowX <= timeXCoords.sleepX) {
        glowCtx.beginPath();
        glowCtx.moveTo(timeXCoords.wakeX, glowCanvas.height);
        glowCtx.lineTo(timeXCoords.nowX, glowCanvas.height);
        glowCtx.strokeStyle = neonColor;
        glowCtx.lineWidth = 2;
        glowCtx.globalAlpha = baseLineAlpha;
        glowCtx.stroke();
        glowCtx.globalAlpha = 1;
    }
}

export function drawHighlightedHourLine() {
    const now = new Date();
    const currentHour = now.getHours();
    const offsetResults = updateWakeSleepX();
    //this is why this function is getting called every second...
    const midpointPosition = offsetResults.midpointPosition;
    let currentHourX;

    // Calculate highlighted hour line position
    let hourInMinutes = currentHour * 60;
    let rawX = hourInMinutes * (glowCanvas.width / 1440);
    currentHourX = (rawX - (midpointPosition - (glowCanvas.width / 2)) + glowCanvas.width) % glowCanvas.width;

    if (currentHourX !== undefined) {
        glowCtx.beginPath();
        glowCtx.moveTo(currentHourX, 0);
        glowCtx.lineTo(currentHourX, glowCanvas.height);
        glowCtx.strokeStyle = neonColor;
        glowCtx.lineWidth = 1;
        glowCtx.globalAlpha = clockStyle().currentHourLineAlpha;
        glowCtx.stroke();
        glowCtx.globalAlpha = 1;
    }
}

function drawNowLine() {
    if (timeXCoords.nowX >= timeXCoords.wakeX && timeXCoords.nowX <= timeXCoords.sleepX) {
        // const nowY = getYPositionOfWave(timeXCoords.nowX);
        // const gradient = glowCtx.createLinearGradient(timeXCoords.nowX, 0, timeXCoords.nowX, glowCanvas.height);
        
        // // Define the extents of the gradient effect
        // const fadeStart = 0; // Start fade in at 10% of the canvas height
        // const fadeEnd = 1; // Start fade out at 90% of the canvas height
       
        // gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
        // gradient.addColorStop(fadeStart, 'rgba(0, 0, 0, 0)');
        // gradient.addColorStop((nowY - 60) / glowCanvas.height, neonColor);
        // gradient.addColorStop(nowY / glowCanvas.height, neonColor);
        // gradient.addColorStop((nowY + 60) / glowCanvas.height, neonColor);
        // gradient.addColorStop(fadeEnd, 'rgba(0, 0, 0, 0)');
        // gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

        glowCtx.beginPath();
        glowCtx.moveTo(timeXCoords.nowX, 0);
        glowCtx.lineTo(timeXCoords.nowX, glowCanvas.height);
        glowCtx.strokeStyle = neonColor; //gradient
        glowCtx.lineWidth = 1;
        glowCtx.stroke();
    }
}

// export function toolTip() {
//     tooltip = document.createElement('div');
//     tooltip.id = 'time-tooltip';
//     tooltip.style.display = 'none'; // Hidden by default
//     document.body.appendChild(tooltip);
// }