//settingsMenuDisplay.js
//////////////////////////////////////////////////////////////////
// variables
import NoSleep from 'nosleep.js';
const noSleep = new NoSleep(); // to settingsMenuDisplay

import { 
    updateCanvas //redcalculates and redraws after settings change or resize
} from "./setup";

import { updateColorStyles } from "./glowStyleDisplay"; //applies updated colors to styles

import { 
    resizeSettingsContainer, resizeButton, isValidHexColor, //Helpers
} from './calculation';

export let glowColor = localStorage.getItem('savedGlowColor') || '#eb9b34';
export let wakeTime = localStorage.getItem('savedWakeTime') || '07:00';
export let sleepTime = localStorage.getItem('savedSleepTime') || '23:00';
// Retrieve the saved state from local storage and convert to a boolean
export let isNoSleepEnabled = localStorage.getItem('savedNoSleep') ? localStorage.getItem('savedNoSleep') === 'true' : false;
    // console.log('initializing isNoSleepEnabled from saved state', isNoSleepEnabled);
export let showTime = localStorage.getItem('savedShowTime') ? localStorage.getItem('savedShowTime') === 'true' : true;

export let wakeTimeInput = createInput('wake-time', wakeTime);
export let sleepTimeInput = createInput('sleep-time', sleepTime);
export let glowColorInput = createInput('glow-color', glowColor);
export let noSleepInput = createInput('checkbox', 'no-sleep', isNoSleepEnabled, 'noSleepLabelArea', 'noSleepToggleArea');
    // console.log('initializing noSleepInput', isNoSleepEnabled);
export let showTimeInput = createInput('checkbox', 'show-time', showTime, 'showTimeLabelArea', 'showTimeToggleArea');

const settingsMenu = document.querySelector('.settingsContainer');
const settingsColorOverlay = document.querySelector('.settingsColorOverlay')

let installButton = null;
let deferredInstallPrompt = null;

///////////////////////////////////////////////////////////////////////////////
if (isNoSleepEnabled) {
    noSleep.enable();
  } else {
    noSleep.disable();
  }

//   console.log('some random code', isNoSleepEnabled);

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later
    deferredInstallPrompt = e;
    // Update UI to show the install button
    showInstallButton();
  });

  function showInstallButton() {
    installButton = document.getElementById('install-button');
    if (installButton) {
      installButton.style.display = 'block';
    }
  }

export function createSettingsMenu() {
    // Grab references to the new HTML containers
    const settingsContainer = document.querySelector('.settingsContainer');
    const settingsBody = document.querySelector('.settingsBody');
    const windowSettings = document.querySelector('.windowSettings');
    const timeSettings = document.querySelector('.timeSettings');
    const customizationSettings = document.querySelector('.customizationSettings');

    settingsColorOverlay.appendChild(settingsContainer);

    settingsContainer.style.display = 'none'; // Initially hidden
    settingsColorOverlay.style.display = 'none';
   
    //Title Bar
    populateTitleBar();

    //Settings Body
    settingsBody.appendChild(windowSettings);
    settingsBody.appendChild(timeSettings);
    settingsBody.appendChild(customizationSettings);

    // Install Settings Buttons
    populateWindowSettings();

    // Update the timeSettings content
    populateTimeSettings(wakeTime, sleepTime);

    // Update Customization Settings
    populateCustomizationSettings(glowColor, isNoSleepEnabled, showTime);

    const showTimeCheckbox = document.getElementById('show-time'); // Ensure ID matches
    if (showTimeCheckbox) {
        showTimeCheckbox.checked = showTime;
    }

    // // Footer
    populateFooter();

    // Set the values of the input fields in the settings menu
        // console.log ('Create Settings Menu Function')
        // console.log ('values for settings Inputs: ', 'wake', wakeTime, 'sleep', sleepTime, 'glowColor', glowColor, 'noSleep', noSleep, 'showTime', showTime);

        if (wakeTimeInput) wakeTimeInput.value = wakeTime;
        if (sleepTimeInput) sleepTimeInput.value = sleepTime;
        if (glowColorInput) glowColorInput.value = glowColor;
        if (noSleepInput) noSleepInput.checked = noSleep === 'true';
        if (showTimeInput) showTimeInput.checked = showTime;
        // console.log('create settingsmenu', isNoSleepEnabled);
    resizeSettingsContainer();
}

// Function to load all settings from localStorage
export function loadSettings() {
        // console.log ('loadSettingsMenu function')
        // console.log('wake, sleep, glow', wakeTime, sleepTime, glowColor);
        // console.log ('values for settings Inputs: ', 'wakeTime', wakeTime, 'sleepTime', sleepTime, 'glowColor', glowColor, 'noSleep', noSleep, 'showTime', showTime);
        // console.log('wake, sleep, glow', wakeTime, sleepTime, glowColor);
    let hexCheckGlowColor = localStorage.getItem('savedGlowColor');
        //console.log('FUNCTION LOADSETTINGS checking savedGlowColor for valid hex: ', storedGlowColor);
    glowColor = isValidHexColor(hexCheckGlowColor) ? hexCheckGlowColor : '#eb9b34'; // Default color
        //console.log('FUNCTION LOADSETTINGS making glowColor savedGlowColor OR default: ', glowColor);

    // Function to check if time is in HH:MM format
    function isValidTimeFormat(time) {
        return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
    }

    wakeTime = getTimeOrUseDefault('savedWakeTime', '07:00');
    sleepTime = getTimeOrUseDefault('savedSleepTime', '23:00');
    isNoSleepEnabled = localStorage.getItem('savedNoSleep') || 'false';
    showTime = localStorage.getItem('savedShowTime') || 'true';

    // Check if the value is in a valid format ('true' or 'false')
    if (isNoSleepEnabled !== 'true' && isNoSleepEnabled !== 'false') {
        console.warn("Invalid format for isNoSleepEnabled in localStorage. Setting to default: false");
        isNoSleepEnabled = 'false';
        localStorage.setItem('savedNoSleep', 'false');
    }

    // Convert string to boolean
    isNoSleepEnabled = isNoSleepEnabled === 'true';

    // console.log('loadSettings', isNoSleepEnabled);

    // Enable or disable noSleep based on the loaded setting

    if (isNoSleepEnabled) {
        noSleep.enable();
    } else {
        noSleep.disable();
    }

    // Function to get time from localStorage or default
    function getTimeOrUseDefault(key, defaultValue) {
        let time = localStorage.getItem(key);
        // console.log ('is this the right format?: ',localStorage.getItem(key));
        if (isValidTimeFormat(time)) {
            // console.log('yes!');
            return time;
        } else {
            localStorage.removeItem(key); // Remove invalid format from localStorage
            // console.log ('no! using: ', defaultValue);
            return defaultValue;
        }
    }

    // console.log ('values for settings Inputs: ', 'wake', wakeTime, 'sleep', sleepTime, 'glowColor', glowColor, 'noSleep', noSleep, 'showTime', showTime);

//    console.log ('after parsing time : values for settings Inputs: ', 'wake', wakeTime, 'sleep', sleepTime, 'glowColor', glowColor, 'noSleep', noSleep, 'showTime', showTime);

    // console.log('Loaded settings:', { wakeTimeInput, sleepTimeInput, glowColorInput, noSleepInput, showTimeInput });

    if (wakeTimeInput && sleepTimeInput && glowColorInput && showTimeInput && noSleepInput) {
        updateColorStyles();
    } else {
        console.error("Input elements not found");
    }
    // Update the tooltip text color
    // const tooltip = document.getElementById('time-tooltip');
    // if (tooltip) {
    //     tooltip.style.color = glowColor;
    // }
}

export function toggleSettingsMenu() {
    const settingsMenu = document.querySelector('.settingsContainer');
    const settingsColorOverlay = document.querySelector('.settingsColorOverlay');
    
    if (settingsMenu) {
        const isMenuVisible = settingsMenu.style.display === 'none' || settingsMenu.style.display === '';

        settingsMenu.style.display = isMenuVisible ? 'grid' : 'none';
        settingsColorOverlay.style.display = isMenuVisible ? 'block' : 'none';
    } else {
        console.error('Settings menu element not found');
    }
}

function applySettings() {
    const wakeTimeInput = document.getElementById('wake-time');
    const sleepTimeInput = document.getElementById('sleep-time');
    const glowColorInput = document.getElementById('glow-color');
    const noSleepInput = document.getElementById('no-sleep');
    const showTimeInput = document.getElementById('show-time');
    // console.log('applyingsettings', isNoSleepEnabled);

    if (wakeTimeInput && sleepTimeInput && glowColorInput && noSleepInput && showTimeInput) {
        wakeTime = wakeTimeInput.value;
        sleepTime = sleepTimeInput.value;
        glowColor = glowColorInput.value;
        isNoSleepEnabled = noSleepInput.checked;
        showTime = showTimeInput.checked;
        // console.log('gettingNoSleep from interface', isNoSleepEnabled);

        localStorage.setItem('savedWakeTime', wakeTime);
        localStorage.setItem('savedSleepTime', sleepTime);
        localStorage.setItem('savedGlowColor', glowColor);
        localStorage.setItem('savedNoSleep', noSleep);
        localStorage.setItem('savedShowTime', showTimeInput.checked.toString());
        // console.log('saving NoSleep setting', isNoSleepEnabled);

        // console.log('closing settings with wake, sleep, glow', wakeTime, sleepTime, glowColor);
        // console.log('corresponding inputs', wakeTimeInput.value, sleepTimeInput.value, glowColorInput.value);
    // Update the global variables

        // Redraw or update the clock as needed
        // console.log ('updating canvas after settings applied')
        // console.log('wake, sleep', wakeTime, sleepTime);
        updateColorStyles();
        updateCanvas();

        // Close the settings menu
        settingsMenu.style.display = 'none';
        settingsColorOverlay.style.display = 'none';
    }
}

// Function to handle pop-out button click
function popOutWindow() {
    window.open(window.location.href, 'possibilityClock', 'width=800,height=200,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
}

// Function to handle install app button click (placeholder)
function installApp() {
    if (deferredInstallPrompt) {
        // Show the install prompt to the user
        deferredInstallPrompt.prompt();

        // Wait for the user to respond to the prompt
        deferredInstallPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }

            // Reset the deferred prompt variable, as it can only be used once
            deferredInstallPrompt = null;

            // Update the install button display or any other UI elements
            const installButton = document.getElementById('install-button');
            if (installButton) {
                installButton.style.display = 'none';
            }
        });
    }
}

function toggleNoSleepMode() {
    isNoSleepEnabled = !isNoSleepEnabled; // Toggle the state
    localStorage.setItem('savedNoSleep', isNoSleepEnabled.toString()); // Save the new state

    if (isNoSleepEnabled) {
        noSleep.enable(); // Enable wake lock
        console.log('No Sleep Mode Enabled');
    } else {
        noSleep.disable(); // Disable wake lock
        console.log('No Sleep Mode Disabled');
    }
}

function toggleShowTime() {
    showTime = !showTime;
    localStorage.setItem('savedShowTime', showTime.toString());

    const lowerLeftElement = document.getElementById('lowerLeft-content');
    if (lowerLeftElement) {
        lowerLeftElement.style.display = showTime ? 'block' : 'none';
    } else {
        console.error('LowerLeft element not found');
    }
}

function closeSettings() {
    settingsMenu.style.display = 'none';
    settingsColorOverlay.style.display = 'none';
}
  


////////////////////////////////////////////////////////////////////////
//settings menu section creators

// Populate the title bar with the title and close button
function populateTitleBar() {
    const settingsTitleBar = document.querySelector('.settingsTitleBar');
    const title = document.createElement('header');
    title.textContent = 'possibilityClock';
    settingsTitleBar.appendChild(title);

    const closeButton = createButton('close-button', '', closeSettings);
    const icon = createIcon('mdi-close-thick');
    closeButton.appendChild(icon);
    settingsTitleBar.appendChild(closeButton);

    const settingsDivider = document.createElement('div'); // Create a 'div' element, not 'divider'
    settingsDivider.className = 'divider'; // Assign the class name 'divider' to the div
    settingsTitleBar.appendChild(settingsDivider); // Append the divider to the title bar area
    
}


// Populate the window settings with install and pop-out buttons
function populateWindowSettings() {
    const windowSettings = document.querySelector('.windowSettings');
    if (!installButton) {
      installButton = createButton('install-button', 'Install App', installApp);
      installButton.style.display = 'none'; // Initially hidden
      windowSettings.appendChild(installButton);
    }
    const popoutButton = createButton('popout-button', 'Pop-out', popOutWindow);
    windowSettings.appendChild(popoutButton);
}

function populateTimeSettings() {
    const timeSettings = document.querySelector('.timeSettings');

    // Create wake time input
    const wakeTimeInput = createInput('time', 'wake-time', 'Wake Time', wakeTime, 'wakeTimeLabelArea', 'wakeTimeInputArea');
    timeSettings.appendChild(wakeTimeInput);

    // Create sleep time input
    const sleepTimeInput = createInput('time', 'sleep-time', 'Sleep Time', sleepTime, 'sleepTimeLabelArea', 'sleepTimeInputArea');
    timeSettings.appendChild(sleepTimeInput);
}

function populateCustomizationSettings(glowColor, noSleep, showTime) {
    const customizationSettings = document.querySelector('.customizationSettings');

    // Glow Color Input
    const glowColorInput = createInput('color', 'glow-color', 'Glow Color', glowColor, 'glowColorLabelArea', 'glowColorSwatchArea');
    customizationSettings.appendChild(glowColorInput);

    // No Sleep Toggle
    const noSleepToggle = createToggle('no-sleep', 'No Sleep Mode', isNoSleepEnabled, toggleNoSleepMode, 'noSleepLabelArea', 'noSleepToggleArea');
    customizationSettings.appendChild(noSleepToggle);

    // Show Time Toggle
    const showTimeToggle = createToggle('show-time', 'Show Time', showTime, toggleShowTime, 'showTimeLabelArea', 'showTimeToggleArea');
    customizationSettings.appendChild(showTimeToggle);
}


// Populate the footer with copyright and apply button
function populateFooter() {
    const settingsFooter = document.querySelector('.settingsFooter');
    const copyright = document.createElement('div');
    copyright.textContent = '© 2024 Stuff is Parts LLC';
    copyright.classList.add('copyright-text');
    settingsFooter.appendChild(copyright);

    // Create the apply button with an icon instead of text
    const applyButton = createButton('apply-button', '', applySettings); // Pass an empty string for text
    const icon = createIcon('mdi-check-bold'); // Assuming createIcon returns an icon element
    applyButton.appendChild(icon); // Add the icon to the button
    settingsFooter.appendChild(applyButton);
}

/////////////////////////////////////////////////////////////////////////////
//element creator functions

// Utility to create a standard button
export function createButton(id, text, onClickFunction) {
    const button = document.createElement('button');
    button.id = id;
    if (text) {
        button.textContent = text; // Only set text if it's not empty
    }
    button.addEventListener('click', onClickFunction);
    return button;
}

// Function to easily add icons to buttons //lowerPanelDisplay, settingsMenuDisplay
export function createIcon(iconName) {
    const iconElement = document.createElement('i');
    iconElement.className = `mdi ${iconName}`;
    return iconElement;
}

// Create an input field with a label
export function createInput(type, id, labelText, defaultValue, labelGridArea, inputGridArea) {
    const inputContainer = document.createElement('div');
    inputContainer.style.display = 'contents'; // This makes the container transparent to the grid

    const label = document.createElement('label');
    label.htmlFor = id;
    label.textContent = labelText;
    label.style.gridArea = labelGridArea; // Assign grid area to label

    const input = document.createElement('input');
    input.type = type;
    input.id = id;
    input.value = defaultValue;
    input.style.gridArea = inputGridArea; // Assign grid area to input

    inputContainer.appendChild(label);
    inputContainer.appendChild(input);
    return inputContainer;
}

function createToggle(id, labelText, defaultValue, toggleFunction, labelGridArea, toggleGridArea) {
    const toggleContainer = document.createElement('div');
    toggleContainer.style.display = 'contents'; // This makes the container transparent to the grid

    const label = document.createElement('label');
    label.htmlFor = id;
    label.textContent = labelText;
    label.style.gridArea = labelGridArea; // Assign grid area to label

    const toggle = document.createElement('input');
    toggle.type = 'checkbox';
    toggle.id = id;
    toggle.checked = defaultValue;
    toggle.style.gridArea = toggleGridArea; // Assign grid area to toggle

    // Add event listener to the toggle
    toggle.addEventListener('change', toggleFunction);

    toggleContainer.appendChild(label);
    toggleContainer.appendChild(toggle);
    return toggleContainer;
}

export function createCloseButton() {
    const closeButton = document.createElement('button');
    closeButton.id = 'close-button';
    closeButton.appendChild(createIcon('mdi-close-outline'));
    closeButton.onclick = function() {
        settingsMenu.style.display = 'none';
        settingsColorOverlay.style.display = 'none';
    };
    resizeButton(closeButton);
    return closeButton;
}

// function createAmPmToggle(inputId, container) {
//         // console.log(']Trying to create AM/PM toggle for:', inputId);
//     const input = document.getElementById(inputId);
//     if (!input) {
//         console.error(`Input with ID ${inputId} not found`);
//         return;
//     }
    
//     const amPmToggle = document.createElement('span');
//     amPmToggle.className = 'am-pm-toggle';
//     amPmToggle.textContent = inputId.includes('wake') ? 'AM' : 'PM'; // This sets wake times to AM and sleep times to PM by default
//     amPmToggle.style.cursor = 'pointer';
//     amPmToggle.onclick = function() {
//         let [hours, minutes] = input.value.split(':');
//         hours = parseInt(hours, 10);
//         if (hours >= 12) {
//             hours -= 12;
//             amPmToggle.textContent = 'AM';
//         } else if (hours === 0) {
//             hours = 12;
//             amPmToggle.textContent = 'PM';
//         } else {
//             hours += 12;
//             amPmToggle.textContent = 'PM';
//         }
//         hours = hours.toString().padStart(2, '0');
//         input.value = `${hours}:${minutes}`;
//     };
    
//     // Append the amPmToggle span to the container, not the input again
//     container.appendChild(amPmToggle);
// }