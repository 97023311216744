//src/features/pEvents.js
//console.log ('pEvents.js running');
import { getYPositionOfWave } from "../upperClockDisplay";
import { trackerCanvas, trackCtx } from "../setup";
import { glowColor } from "../settingsMenuDisplay";
import { updateWakeSleepX } from "../calculation";

export function initEvents() {
    //console.log('initEvents called');
    let mouseX = 0;
    const tooltip = document.getElementById('time-tooltip');

    if (!trackerCanvas) {
        console.error('trackerCanvas element not found');
        return;
    } else {
        //console.log('trackerCanvas found:', trackerCanvas);
    }

    if (!trackCtx) {
        console.error('Failed to get 2D context from trackerCanvas');
        return;
    } else {
        //console.log('trackCtx obtained:', trackCtx);
    }
    
    trackerCanvas.addEventListener('mousemove', function(event) {
        mouseX = event.offsetX; // Get the X position of the mouse       
        let mouseY = getYPositionOfWave(mouseX); // Calculate mouseY based on the sine wave
    //    console.log('Mouse move event: X=' + mouseX + ', Y=' + mouseY);
        drawVerticalLine(mouseX, mouseY); // Draw line at current mouse position

        const timeString = getTimeFromX(mouseX); // Convert mouseX to time string
        const tooltipWidth = tooltip.offsetWidth;
        const tooltipHeight = tooltip.offsetHeight;
        const offset = -15; // Distance above the mouse pointer
        tooltip.style.display = 'block';
        tooltip.style.left = (mouseX - tooltipWidth / 2) + 'px';
        tooltip.style.top = (mouseY - tooltipHeight - offset) + 'px';
        tooltip.textContent = timeString;
    });

    trackerCanvas.addEventListener('mouseout', function() {
        //console.log('Mouse out event');
        trackCtx.clearRect(0, 0, trackerCanvas.width, trackerCanvas.height);
        tooltip.style.display = 'none'; // Hide tooltip when mouse leaves the canvas
    });

    trackerCanvas.addEventListener('click', function() {
        //console.log('Click event at X=' + mouseX);
        createEventPoint(mouseX);
        // Display time entry field logic here
    });

    function drawVerticalLine(mouseX, mouseY) {
        //console.log('Drawing vertical line at:', mouseX, mouseY);
        trackCtx.clearRect(0, 0, trackerCanvas.width, trackerCanvas.height); // Clear previous line
        trackCtx.beginPath();
        trackCtx.moveTo(mouseX, trackerCanvas.height);
        trackCtx.lineTo(mouseX, mouseY);
        trackCtx.strokeStyle = glowColor; // Make sure this is set
        trackCtx.lineWidth = 1; // Set a visible line width
        trackCtx.stroke();
    }

    function createEventPoint(x) {
        // Logic to create and store the event point
        //console.log('createEventPoint called at X=' + x);
        // Update and redraw the event arcs as needed
    }
}

function getTimeFromX(x) {
    const { wrapOffset } = updateWakeSleepX();
    const scalingFactor = trackerCanvas.width / 1440; // Scaling factor based on canvas width
    const offsetInMinutes = wrapOffset / scalingFactor; // Convert wrapOffset to minutes

    let totalMinutes = (x / scalingFactor) + offsetInMinutes; // Adjust X position by offset

    // Wrap around if totalMinutes is outside a day's range
    totalMinutes = totalMinutes % 1440;
    if (totalMinutes < 0) totalMinutes += 1440;

    let hours = Math.floor(totalMinutes / 60);
    let minutes = Math.floor(totalMinutes % 60);
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${amPm}`;
}

export function updateTooltipStyle() {
    const tooltip = document.getElementById('time-tooltip');
    if (tooltip) {
        tooltip.style.color = glowColor;
    //    tooltip.style.borderColor = glowColor;
    }
}