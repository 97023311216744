//calculation.js

export let waveAmplitude;
export let centerY;

import { drawTimeGlow, timeXCoords } from './upperClockDisplay.js';

import {
    wakeTime, sleepTime
} from './settingsMenuDisplay.js';

import {
    clockContainer, baseCanvas, glowCanvas,
    effectsCanvas, trackerCanvas, labelArea
} from './setup.js';

import { 
    updateLowerLeftContent
} from './glowStyleDisplay.js';

const minutesInDay = 24 * 60; //to calculation
let wrapOffset = 0;

export let parsedWakeTime;
export let parsedSleepTime;

///////////////////////////////////////////////////////////
//setters

export function setCanvasSize() {
    // console.log('starting setCanvasSize wake, sleep', wakeTime, sleepTime);
    //get the size of the window
    const rect = clockContainer.getBoundingClientRect();
    //make the canvas the width of the window and 80% of its height
    baseCanvas.width = rect.width;
    baseCanvas.height = (rect.height * 0.8);
    //set the css pixel resolution of the canvas to match the physical pixels
    baseCanvas.style.width = baseCanvas.width + 'px';
    baseCanvas.style.height = baseCanvas.height + 'px';
    //setglowCanvas related dimensions accordingly
    glowCanvas.width = baseCanvas.width;
    glowCanvas.height = baseCanvas.height;
    glowCanvas.style.width = glowCanvas.style.width + 'px';
    glowCanvas.style.height = glowCanvas.style.height + 'px';
    //seteffectsCanvas related dimensions likewise
    effectsCanvas.width = baseCanvas.width;
    effectsCanvas.height = baseCanvas.height;
    effectsCanvas.style.width = effectsCanvas.width + 'px';
    effectsCanvas.style.height = effectsCanvas.height + 'px';

    trackerCanvas.width = baseCanvas.width;
    trackerCanvas.height = baseCanvas.height;
    trackerCanvas.style.width = trackerCanvas.width + 'px';
    trackerCanvas.style.height = trackerCanvas.height + 'px';

    timeXCoords.endX = baseCanvas.width
    waveAmplitude = baseCanvas.height * 0.4;
    centerY = baseCanvas.height / 2;
        // console.log('running setCanvasSize');
        // console.log('waveAmplitude: ',waveAmplitude);
        // console.log('centerY: ',centerY);
        // console.log('ending setCanvasSize wake, sleep', wakeTime, sleepTime);
  }

////////////////////////////////////////////////////////////////////

export function updateNowX() {
    const offsetResults = updateWakeSleepX(wakeTime, sleepTime);
    const midpointPosition = offsetResults.midpointPosition;
    const scalingFactor = baseCanvas.width / 1440;
    const now = new Date();
    let nowMinutes = now.getHours() * 60 + now.getMinutes();
    // console.log('nowX variables - nowMinutes, parsedWakeTime.hour, midpointPosition, scalingFactor: ', nowMinutes, parsedWakeTime.hour, midpointPosition, scalingFactor)
    if (now.getHours() < parsedWakeTime.hour || (now.getHours() === parsedWakeTime.hour && now.getMinutes() < parsedWakeTime.minute)) {
        nowMinutes += 1440;
    }

    timeXCoords.nowX = ((nowMinutes * scalingFactor) - (midpointPosition - baseCanvas.width / 2) + baseCanvas.width) % baseCanvas.width;
        //console.log ('nowX: ',timeXCoords.nowX);
    drawTimeGlow(timeXCoords);
    updateLowerLeftContent(now);  // Passing the current date object to the function
    // console.log('nowX updated. ', timeXCoords.nowX);
}

export function calculatePositionFromTime() {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();

    const totalMinutes = hour * 60 + minute;
    let positionFromTime = ((baseCanvas.width / minutesInDay) * totalMinutes) + wrapOffset;

    // Ensure the position wraps around the canvas width
    positionFromTime = positionFromTime % baseCanvas.width;
    if (positionFromTime < 0) {
        positionFromTime += baseCanvas.width; // Adjust if position is negative
    }

    // console.log('4.calculatePositionFromTime - positionFromTime:', positionFromTime);
        // console.log('running calculatePositionFromTime');
        // console.log('waveAmplitude: ',waveAmplitude);
        // console.log('centerY: ',centerY);

    return positionFromTime;
}

export function updateWakeSleepX() {
    parsedWakeTime = parseTime(wakeTime);
    parsedSleepTime = parseTime(sleepTime);

    wakeMinutes = parsedWakeTime.hour * 60 + parsedWakeTime.minute;
    sleepMinutes = parsedSleepTime.hour * 60 + parsedSleepTime.minute;

    if (parsedSleepTime.hour < parsedWakeTime.hour || (parsedSleepTime.hour === parsedWakeTime.hour && parsedSleepTime.minute < parsedWakeTime.minute)) {
        sleepMinutes += 1440;
    }

    const midpointInMinutes = (wakeMinutes + sleepMinutes) / 2;
    const scalingFactor = baseCanvas.width / 1440;
    const midpointPosition = midpointInMinutes * scalingFactor;
    const baseCanvasCenter = baseCanvas.width / 2;
    let offset = midpointPosition - baseCanvasCenter;
    let wrapOffset = (baseCanvas.width + offset) % baseCanvas.width;
    let wakeMinutes = parsedWakeTime.hour * 60 + parsedWakeTime.minute;
    let sleepMinutes = parsedSleepTime.hour * 60 + parsedSleepTime.minute;

    timeXCoords.wakeX = (wakeMinutes * scalingFactor - (midpointPosition - baseCanvas.width / 2) + baseCanvas.width) % baseCanvas.width;
    timeXCoords.sleepX = (sleepMinutes * scalingFactor - (midpointPosition - baseCanvas.width / 2) + baseCanvas.width) % baseCanvas.width;
    return { offset, wrapOffset, midpointPosition };
}

//This is for the tooltip over the nowX dot
// export function getCurrentTimeFormatted() {
//     const now = new Date();
//     let hours = now.getHours();
//     const minutes = now.getMinutes();
//     const amPm = hours >= 12 ? 'p' : 'a';
//     hours = hours % 12 || 12; // the hour '0' should be '12'
//     const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
//     return `${hours}:${minutesFormatted}${amPm}`;
// }

export function parseTime(timeString) {
    const parts = timeString.split(':');
    return {
        hour: parseInt(parts[0], 10),
        minute: parseInt(parts[1], 10)
    };
}

// // Function to convert hex color to RGBA for transparency
export function getTransparentColor(hexColor, opacity) {
    //console.log('get transparent rgb value from: ', hexColor, 'with opacity: ', opacity);
    // Convert hex to RGB
    let rgb = hexColor.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${rgb.join(', ')}, ${opacity})`;
}

export function isValidHexColor(hex) {
    // Regex to check if the string is a valid hex color code
    const regex = /^#[0-9A-F]{6}$/i;
    return regex.test(hex);
}
///////////////////////////////////////////////////////////////////////////////////
//resizing

export function resizeButton(button) {
    const buttonSize = labelArea.offsetHeight * 0.75;
    button.style.height = `${buttonSize}px`;
    button.style.width = `${buttonSize}px`; 
    button.style.fontSize = `${buttonSize * 0.65}px`;
}

export function resizeSettingsContainer() {
    const clockContainer = document.getElementById('clock-container');
    const settingsContainer = document.querySelector('.settingsContainer');
  
    if (clockContainer && settingsContainer) {
      const clockRect = clockContainer.getBoundingClientRect();
      
      // Here you can define how you want to scale relative to the clock container
      // For example, if you want the settings to be 80% of the clock container's width
      settingsContainer.style.width = `(${clockRect.width} * 0.8)px`;
      settingsContainer.style.height = `${clockRect.height} * 0.8)px`;
    }
}