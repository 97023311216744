//lowerPanelDisplay.js

import {
    baseCtx,
    labelArea, baseCanvas
} from "./setup";

import { 
    toggleSettingsMenu, createButton, createIcon,
    wakeTime, sleepTime, glowColor
} from "./settingsMenuDisplay";

import {
    getTransparentColor,
    parsedWakeTime, parsedSleepTime, updateWakeSleepX
} from "./calculation";

let hourLabels = [];

export function drawLabelPanel() {
    // console.log('drawlabelpanel! before drawboundaryline wake, sleep', wakeTime, sleepTime);
    drawBoundaryLine();
    // console.log('wake, sleep', wakeTime, sleepTime);
    // console.log('drawlabelpanel! before create labels wake, sleep', wakeTime, sleepTime);
    createLabels(wakeTime, sleepTime);
    createSettingsButton();
    resizeSettingsButton();
    resizeIcon();
    // console.log('after drawlabelpanel wake, sleep', wakeTime, sleepTime);
}


export function drawBoundaryLine() {
    baseCtx.beginPath();
    baseCtx.moveTo(0, baseCanvas.height);
    baseCtx.lineTo(baseCanvas.width, baseCanvas.height);
    baseCtx.strokeStyle = '#1e4f55';
    baseCtx.lineWidth = 2;
    baseCtx.stroke();
}

export function createLabels(wakeTime, sleepTime) {
    // console.log('starting create labels wake, sleep', wakeTime, sleepTime);
    // Clear any existing labels from the DOM and the hourLabels array
    hourLabels = []; // Reset the array
    document.querySelectorAll('.hour-label, .special-time-label').forEach(label => label.remove());

    // Retrieve the midpointOffset results to determine the center point for the labels
    const offsetResults = updateWakeSleepX(wakeTime, sleepTime);
    const midpointPosition = offsetResults.midpointPosition;
    
    // Iterate over each hour in a 24-hour period to create labels
    for (let hour = 0; hour < 24; hour++) {
            // console.log('making label for hour: ', hour);
            // console.log('parsedWakeTime.hour: ', parsedWakeTime.hour);
        // Check if the current hour falls within the wake and sleep time range
        // console.log('before istimeinrange labels wake, sleep', wakeTime, sleepTime);
        if (isTimeInRange(hour, parsedWakeTime, parsedSleepTime)) {
            // Adjust the hour for labeling if needed (e.g., for times after midnight)
            let labelHour = hour < parsedWakeTime.hour ? hour + 24 : hour;

            // Format the label string (e.g., "1 AM", "2 PM") based on the hour
            // console.log('before formatlabelstring wake, sleep', wakeTime, sleepTime);
            let labelText = formatLabelString(labelHour);

            // Calculate the horizontal position of the label based on the hour
            // console.log('before calculatelabelposition wake, sleep', wakeTime, sleepTime);
            let position = calculateLabelPosition(hour, midpointPosition);

            // Create the label element with the calculated position and text
            // console.log('before createlabelelement wake, sleep', wakeTime, sleepTime);
            let labelElement = createLabelElement(position, labelText, 'hour-label', labelHour);

            // Add the label element to the label area in the DOM
            labelArea.appendChild(labelElement);

            // Store the label element along with its associated hour for later reference
            hourLabels.push({ element: labelElement, hour: labelHour });
        }
    }

    // Update label styles immediately to reflect the current time
    // console.log('wake, sleep', wakeTime, sleepTime);
    updateHourLabelStyles();
}

//calculate whether each hour is during wake time (drawn) or sleep time (not drawn)
function isTimeInRange(hour, parsedWakeTime, parsedSleepTime) {
    // console.log('starting istimeinrange wake, sleep', wakeTime, sleepTime);
    let currentMinutes = hour * 60;
    let wakeMinutes = parsedWakeTime.hour * 60 + parsedWakeTime.minute;
    let sleepMinutes = parsedSleepTime.hour * 60 + parsedSleepTime.minute;
    // Adjust for sleep time crossing over to the next day
    if (parsedSleepTime.hour < parsedWakeTime.hour || (parsedSleepTime.hour === parsedWakeTime.hour && parsedSleepTime.minute < parsedWakeTime.minute)) {
        sleepMinutes += 24 * 60;
    }
    // Normalize current minutes for comparison if it's less than wakeMinutes, assuming it's the next day
    if (currentMinutes < wakeMinutes) {
        currentMinutes += 24 * 60;
    }
    return currentMinutes >= wakeMinutes && currentMinutes <= sleepMinutes;
}

function formatLabelString(hour, minute = null) {
    // console.log('starting formatlabelstring wake, sleep', wakeTime, sleepTime);
    let hourLabel = hour % 12 === 0 ? 12 : hour % 12;
    let period = hour < 12 || hour >= 24 ? 'AM' : 'PM'; // Adjust for hours beyond 24h
    hour = hour % 24; // Normalize hour for AM/PM calculation

    if (minute !== null) {
        const formattedMinute = minute < 10 ? `0${minute}` : minute;
        return `${hourLabel}:${formattedMinute} ${period}`;
    }
    return `${hourLabel}<br>${period}`;
}

function calculateLabelPosition(hour, midpointPosition) {
    // console.log('starting calculatelabelposition wake, sleep', wakeTime, sleepTime);
    let hourInMinutes = hour * 60;
    let rawX = hourInMinutes * (baseCanvas.width / 1440);
    let position = (rawX - (midpointPosition - (baseCanvas.width / 2)) + baseCanvas.width) % baseCanvas.width;
    return position;
}

// Function to create a single label element
function createLabelElement(position, labelText, className, labelHour) {
    // console.log('starting createlabelelement wake, sleep', wakeTime, sleepTime);
    const labelDiv = document.createElement('div');
    labelDiv.className = className;
    labelDiv.innerHTML = labelText;
    labelDiv.style.position = 'absolute';
    labelDiv.style.left = `${position}px`;
    labelDiv.style.transform = 'translateX(-50%)';
    labelDiv.style.whiteSpace = 'nowrap';
    labelDiv.setAttribute('data-hour', labelHour); // Store the hour in the element

    return labelDiv;
}

export function updateHourLabelStyles() {
    // console.log('starting updatehourlabelstyles wake, sleep', wakeTime, sleepTime);
    const now = new Date();
    let currentHour = now.getHours();
    let currentTimeInMinutes = currentHour * 60 + now.getMinutes();
    // Normalize the current hour for comparison if it's past midnight
    if (currentHour === 0) {
        currentTimeInMinutes += 24 * 60; // Add 24 hours to current time
    }

    hourLabels.forEach(labelInfo => {
        const { element, hour } = labelInfo;
        let labelTimeInMinutes = hour * 60;

        // Adjust label time minutes for labels after midnight
        if (hour < parsedWakeTime.hour) {
            labelTimeInMinutes += 24 * 60; // Add 24 hours to label time
        }

        //    console.log('glowColor for labels: ', glowColor);

        // Determine if the label is for the current hour or a past hour
        if (currentTimeInMinutes >= labelTimeInMinutes && currentTimeInMinutes < labelTimeInMinutes + 60) {
            element.style.color = glowColor; // Full color for the current hour
            element.classList.add('current-hour');
            element.classList.remove('past-hour');
        } else if (currentTimeInMinutes > labelTimeInMinutes) {
            element.style.color = getTransparentColor(glowColor, 0.5); // More transparent for past hours
            element.classList.add('past-hour');
            element.classList.remove('current-hour');
        } else {
            element.style.color = '#1e4f55'; // Default color for future hours
            element.classList.remove('current-hour', 'past-hour');
        }
    });
}

// Create and append the settings button
export function createSettingsButton() {
    // First, remove the existing button if it exists
    var existingButton = document.getElementById('settings-button');
    if (existingButton) {
        existingButton.remove();
    }

    // Use the createButton utility to create a new settings button
    const settingsButton = createButton('settings-button', '', toggleSettingsMenu);
    settingsButton.setAttribute('aria-label', 'Settings');

    // Use the createIcon utility to add an icon to the button
    const settingsIcon = createIcon('mdi-cog');
    settingsButton.appendChild(settingsIcon);

    if (labelArea) {
        labelArea.appendChild(settingsButton)
    } else {
        console.error('Label area not found for settings button');
    }
}

function resizeSettingsButton() {
    const settingsButton = document.getElementById('settings-button');
    if (settingsButton && labelArea) {
        const labelAreaHeight = labelArea.offsetHeight;
        const buttonSize = labelAreaHeight * 0.75; // 75% of the labelArea height
        const spaceToRightEdge = (labelAreaHeight - buttonSize) / 2;

        settingsButton.style.height = `${buttonSize}px`;
        settingsButton.style.width = `${buttonSize}px`;

        // Set the button to be vertically centered in the label area
        settingsButton.style.position = 'absolute';
        settingsButton.style.top = '50%';
        settingsButton.style.right = `${spaceToRightEdge}px`;
        settingsButton.style.transform = 'translateY(-50%)';
        settingsButton.style.margin = '0px';
    } else {
        console.error('Resize Settings Button - Settings button or label area not found');
    }
}

function resizeIcon() {
    // Retrieve the settings button by ID instead of using the settingsButton variable
    const button = document.getElementById('settings-button');
    if (button) {
        const icon = button.querySelector('i'); // Assuming the icon is the only <i> inside the button
        if (icon) {
            const buttonHeight = button.offsetHeight; // Get the button's height
            const iconSize = buttonHeight * 0.9; // 90% of the button's height
            icon.style.fontSize = `${iconSize}px`; // Set the icon's font size
        } else {
            console.error('Icon inside settings button not found');
        }
    } else {
        console.error('Resize Icon - Settings button not found');
    }
}


