//glowStyleDisplay.js
// console.log('glowStyleDisplay has begun');
import { glowCanvas } from "./setup";
import { isDevMode, fetchVersionNumber } from "./devVersioning";
import { glowColor } from "./settingsMenuDisplay";
import { updateTooltipStyle } from "./features/pEvents";

export let neonColor;

export function clockStyle() {
    return {
        currentHourLineAlpha: 0.25,
        nowHourLineAlpha: 0.8,

        sleepFill: glowColor,
        sleepFillAlpha: 0.275,

        timeFill: glowColor,
        timeFillAlpha: 0.19,

        topOverlayColor: '#000000',
        topOverlayAlpha: 0.55,

        baseLineColor: '#1e4f55',
        baseLineAlpha: 0.9,

        wakeLineHighlightAlpha: 0.4,    
        sleepLineHighlightAlpha: 0.6,
        baseLineHighlightAlpha: 0.3
    };
}

// Define the styles for the wake line and the glow separately
export function wakeLineStyle() {
    return {
        glowAlpha: 1,
        lineWidth: 1,
        strokeStyle: neonColor,
        lineAlpha: 1
    };
}

export function wakeLineGlowStyle() {
    return {
        glowAlpha: 0.8,
        glowBlur: 16,
        glowColor: glowColor,
        glowWidth: 6
    };
}

export function sleepLineStyle() {
        return {
            glowAlpha: 0.5,
            lineWidth: .5,
            strokeStyle: neonColor,
            lineAlpha: 1
        };
}

export function sleepLineGlowStyle() {
    return {
        glowAlpha: 0.4,
        glowBlur: 10,
        glowColor: glowColor,
        glowWidth: 5

    };
}

export const wakeDotStyle = () => {
    const baseRadius = glowCanvas.width * 0.00375; // Relative size to the canvas width
    return {
        // Primary dot style
        radius: baseRadius,
        fillColor: neonColor,
        glowAlpha: 0.5
    };
};

export const wakeDotGlowStyle = () => {
    const baseRadius = glowCanvas.width * 0.005; // Relative size to the canvas width
    return {
        glowAlpha: 0.3,
        glowColor: glowColor,
        glowRadius: baseRadius * 2,
        glowOffsetY: -glowCanvas.height
    };
};

export const sleepDotStyle = () => {
    const baseRadius = glowCanvas.width * 0.0025;
    return {
        // Primary dot style
        glowAlpha: 0.7,
        radius: baseRadius,
        fillColor: neonColor
    };
};

export const sleepDotGlowStyle = () => {
    const baseRadius = glowCanvas.width * 0.003; // Relative size to the canvas width
    return {
        glowAlpha: 0.3,
        glowRadius: baseRadius * 3,
        glowColor: glowColor,
        glowOffsetY: -glowCanvas.height
    };
};

export function updateLowerLeftContent(now) {
    const lowerLeftElement = document.getElementById('lowerLeft-content');
    if (lowerLeftElement) {
        if (isDevMode) {
            fetchVersionNumber(); // Show version number in dev mode
        } else {
            // Show current time in production mode
            const timeOptions = { hour: 'numeric', minute: '2-digit' };
            lowerLeftElement.innerHTML = now.toLocaleTimeString([], timeOptions);
        }
        lowerLeftElement.style.color = neonColor; // Update text color to neonColor
    }
}

export function updateColorStyles() {

    // Update styles
   //console.log('updating Styles');
   //console.log('original color(glowColor): ', glowColor);

    neonCore();
    neonColor = neonCore(glowColor); // Capture the returned value from neonCore
    Object.assign(wakeLineStyle, {
        strokeStyle: neonColor,
        shadowColor: glowColor
    });
    Object.assign(wakeLineGlowStyle, {
        glowColor: glowColor
    });
    Object.assign(wakeDotStyle, {
        fillColor: neonColor,
        glowColor: glowColor
    });
    Object.assign(sleepLineStyle, {
        strokeStyle: neonColor,
        shadowColor: glowColor,
    });
    Object.assign(sleepLineGlowStyle, {
        glowColor: glowColor
    });
    Object.assign(sleepDotStyle, {
        fillColor: neonColor,
        glowColor: glowColor
    });

    updateTooltipStyle();
    //console.log('modified color(neonColor): ', neonColor);
}

//derive neonColor from glowColor
    // console.log ('FUNCTION NEONCORE deriving neon color from this glowColor: ', glowColor);
function neonCore() {
        // console.log('running neonCore color modifier');
        // console.log('modifying color ', glowColor);
    const rgb = hexToRgb(glowColor);
    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
    const hslAdjusted = adjustHsl(hsl);
    const rgbAdjusted = hslToRgb(hslAdjusted.h, hslAdjusted.s, hslAdjusted.l);
    const neonColor = rgbToHex(rgbAdjusted.r, rgbAdjusted.g, rgbAdjusted.b);
        // console.log('output of neonCore - neonColor: ', neonColor);
    return neonColor; // Return the neonColor so it can be used immediately
  }

function hexToRgb(hex) {
    //console.log('hex: ', hex);
    if (!hex || hex[0] !== '#' || hex.length !== 7) {
      throw new Error('Invalid HEX color');
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
        // console.log('originalrgb: ',r,g,b);
    return { r, g, b };
}

function rgbToHsl(r, g, b) {
  r /= 255, g /= 255, b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
//    console.log('originalhsl: ',h * 360, s * 100, l * 100 );
  return { h: h * 360, s: s * 100, l: l * 100 };
}

function adjustHsl(hsl) {
    const newH = hsl.h; // Preserve the original hue
    const newS = 100;   // Set saturation to the maximum (100%)
    let newL = 75 - (50 * (1 - hsl.l / 100)); // Adjust lightness
    // console.log('modified hsl: ', newH, newS, newL);
    return { h: newH, s: newS, l: newL }; // Return correct HSL object

  }

function hslToRgb(h, s, l) {
  s /= 100, l /= 100;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;
  let r, g, b;

  if (h < 60) { r = c, g = x, b = 0; }
  else if (h < 120) { r = x, g = c, b = 0; }
  else if (h < 180) { r = 0, g = c, b = x; }
  else if (h < 240) { r = 0, g = x, b = c; }
  else if (h < 300) { r = x, g = 0, b = c; }
  else { r = c, g = 0, b = x; }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
    //  console.log('modifiedrgb: ',r,g,b);
  return { r, g, b };
}

// Fix the rgbToHex function to ensure proper hex string format
function rgbToHex(r, g, b) {
    return "#" + [r, g, b].map(x => {
      const hex = x.toString(16).padStart(2, '0'); // Ensure two digits
        // console.log('final Step neonCore: ', hex);
      return hex;
    }).join('');
  }