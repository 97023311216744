import { timeXCoords, getYPositionOfWave } from "./upperClockDisplay";
import { neonColor } from "./glowStyleDisplay";
import { effectsCanvas, fxCtx } from "./setup";

console.log('running effects.js');

let scaleFactor = 1;
const baseWidth = window.innerWidth;
const initialScaleRelativeToCanvas = 0.075;
let imagesLoaded = 0;
const imageSources = [
    '../assets/nowDotFX01.webp',
    '../assets/nowDotFX02.webp',
    '../assets/nowDotFX03.webp'
];
const images = imageSources.map(src => {
    const img = new Image();
    img.src = src;
    return img;
});

const blendModes = ['lighter', 'lighter', 'lighter'];
const rotationSpeed = [40, -40, 15];
const opacity = [0.5, 0.5, 0.5];
let currentRotation = [0, 0, 0];
let lastTimestamp;

function updateScaleFactor() {
    // Recalculate scale factor based on current window size
    const scaleWidth = window.innerWidth / baseWidth;
    scaleFactor = initialScaleRelativeToCanvas * scaleWidth;
}

export function initEffects() {
    if (!effectsCanvas) {
        console.error('effectsCanvas element not found');
        return;
    }
    if (!fxCtx) {
        console.error('Failed to get 2D context from effectsCanvas');
        return;
    }

    images.forEach((img) => {
        img.onload = () => {
            imagesLoaded++;
            if (imagesLoaded === imageSources.length) {
                console.log('All images loaded, starting animation.');
                requestAnimationFrame(animateNowDotFX);
            }
        };
        img.onerror = () => {
            console.error(`Failed to load image: ${img.src}`);
        };
    });

    // Call this to initially set up the scale factor based on the current size
    updateScaleFactor();
}

window.addEventListener('resize', updateScaleFactor);

function animateNowDotFX(timestamp) {
    const nowY = getYPositionOfWave(timeXCoords.nowX);
    if (!lastTimestamp) {
    lastTimestamp = timestamp;
    }
    const timeDelta = (timestamp - lastTimestamp) / 1000; // Time in seconds
    lastTimestamp = timestamp;

    fxCtx.clearRect(0, 0, effectsCanvas.width, effectsCanvas.height);

    images.forEach((img, index) => {
        fxCtx.save();
        fxCtx.globalCompositeOperation = blendModes[index];
        fxCtx.translate(timeXCoords.nowX, nowY); // Apply scaling to the position
    
        // Update and apply the rotation for each image
        currentRotation[index] += rotationSpeed[index] * timeDelta;
        currentRotation[index] %= 360;
    
        // Apply the opacity for each image
        fxCtx.globalAlpha = opacity[index];

        const scaledWidth = img.width * scaleFactor;
        const scaledHeight = img.height * scaleFactor;

        fxCtx.rotate(currentRotation[index] * Math.PI / 180);
        fxCtx.drawImage(img, -scaledWidth / 2, -scaledHeight / 2, scaledWidth, scaledHeight);
    
        fxCtx.restore();
    });

    applyColorOverlay(); // Apply the color overlay after drawing the images

    requestAnimationFrame(animateNowDotFX);
}


function applyColorOverlay() {
    // This function applies a color overlay on top of whatever has been drawn on the canvas
    fxCtx.globalCompositeOperation = 'source-atop';
    fxCtx.fillStyle = neonColor;
    fxCtx.fillRect(0, 0, effectsCanvas.width, effectsCanvas.height);
    fxCtx.globalCompositeOperation = 'source-over'; // Reset the composite operation
}