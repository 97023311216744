// setup.js
console.log('setup has begun');
import {
    updateNowX, setCanvasSize, resizeSettingsContainer,
    updateWakeSleepX
} from './calculation.js';

import { 
    drawClockGrid, 
    //toolTip, tooltip
} from './upperClockDisplay.js';

import {
    drawLabelPanel, updateHourLabelStyles
} from './lowerPanelDisplay.js';

import {
    createSettingsMenu, loadSettings
} from './settingsMenuDisplay.js';

console.log('loading initEffects and initEvents');
import { initEffects } from './effects.js';

import { initEvents, updateTooltipStyle } from './features/pEvents.js';
console.log ('effects and events run!');
export let offset;
export let clockContainer, baseCanvas, glowCanvas, trackerCanvas, effectsCanvas;
export let labelArea, glowCtx, baseCtx, trackCtx, fxCtx;

//let currentDotX = 0;
//let currentDotY = 0;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DRAW EVERYTHING when the DOM is loaded
document.addEventListener("DOMContentLoaded", function() {
    effectsCanvas = document.getElementById('effects-canvas');
    trackerCanvas = document.getElementById('tracker-canvas');
    glowCanvas = document.getElementById('glow-canvas');
    baseCanvas = document.getElementById('base-canvas');

    glowCtx = glowCanvas.getContext('2d');
    baseCtx = baseCanvas.getContext('2d');
    trackCtx = trackerCanvas.getContext('2d');
    fxCtx = effectsCanvas.getContext('2d');

    clockContainer = document.getElementById('clock-container');
    labelArea = document.getElementById('label-area');

    if (clockContainer && labelArea) {
        createSettingsMenu(); //sets wakeTime & sleepTime values & glowColor
        loadSettings();//settingsMenuDisplay.js - loads and applies saved values or defaults, calculates neonCore, applies styles.
    }
    updateCanvas();
    updateNowX();
    initEffects();
    console.log('running initEffects');
    initEvents(); // Initialize events from pEvents.js
    console.log('running initEvents');
    setInterval(updateNowX, 1000);
    setInterval(updateHourLabelStyles, 60000);
});

// DRAW EVERYTHING ON RESIZE
window.addEventListener('resize', debounce(function() {
    updateCanvas();
}, 250)); // Adjust the 250ms debounce time as needed

export function updateCanvas () {
//   console.log('before updateWakeSleepx wake, sleep', wakeTime, sleepTime);
  updateWakeSleepX();//calculation.js - timeXCoords.wakeX, timeXCoords.sleepX
  baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height);
  glowCtx.clearRect(0, 0, glowCanvas.width, glowCanvas.height);
//   console.log('base and glow canvases have been cleared');
//   console.log('before setCanvasSize wake, sleep', wakeTime, sleepTime);
  setCanvasSize();//calculation.js - sets waveAmplitude, endX and centerY
//   console.log('before drawClockGrid wake, sleep', wakeTime, sleepTime);
  drawClockGrid();//upperClockDisplay.js - drawHourLines, drawStaticOverlays, drawWakeSleepLine, topOverlay();
//   console.log('before drawLabelPanel wake, sleep', wakeTime, sleepTime);
  drawLabelPanel();//lowerPanelDisplay.js - drawBoundaryLine, createLabels, createSettingsButton, resizeIcon
//   console.log('before resizeSettingsContainer wake, sleep', wakeTime, sleepTime);
  resizeSettingsContainer();
 // toolTip();
  //glowCanvas.addEventListener('mousemove', handleMouseMove);
  //glowCanvas.addEventListener('mouseout', () => { tooltip.style.display = 'none'; });
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function isMouseNearDot(event, dotX, dotY) {
    // Calculate if the mouse is near the dot (adjust threshold as needed)
    const threshold = 10;
    const canvasRect = glowCanvas.getBoundingClientRect();
    const mouseX = event.clientX - canvasRect.left;
    const mouseY = event.clientY - canvasRect.top;
    return Math.abs(mouseX - dotX) < threshold && Math.abs(mouseY - dotY) < threshold;
}

// export function handleMouseMove(event) {
//     if (isMouseNearDot(event, currentDotX, currentDotY)) {
//         tooltip.innerHTML = getCurrentTimeFormatted();
//         tooltip.style.display = 'block';
//         tooltip.style.left = `${event.pageX + 10}px`;
//         tooltip.style.top = `${event.pageY + 10}px`;
//         tooltip.style.backgroundColor = 'transparent'; // No background
//         tooltip.style.font = '1.5vw Arial, sans-serif'; // Match the labels font size and family
//         tooltip.style.color = glowColor;
//     } else {
//         tooltip.style.display = 'none';
//     }
// }

// Debounce function to prevent too many redraws on resize
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}